import React, { useState } from "react"
import Lightbox from "react-image-lightbox"
import "react-image-lightbox/style.css"

import "./index.scss"

const ImageGallery = (props) => {
  const [imageIndex, setImageIndex] = useState(0)
  const [isOpen, setIsOpen] = useState(false)

  const images = props.images.map((image, index) => {
    const imageSplit = image.filename.split(".")
    const filename = imageSplit[0].replace(/ /g, "%20")
    return (
      <div
        className="col-lg-3 col-md-4 col-6 text-center gallery-thumbnail"
        key={image.id}
      >
        <img
          src={`${props.path}/${filename}_750.${imageSplit[1]}`}
          className="gallery-thumbnail-image"
          alt=""
          onClick={() => {
            setIsOpen(true)
            setImageIndex(index)
          }}
          srcSet={`
          ${props.path}/${filename}_1000.${imageSplit[1]} 1000w,
          ${props.path}/${filename}_750.${imageSplit[1]} 750w,
          ${props.path}/${filename}_500.${imageSplit[1]} 500w,
          ${props.path}/${filename}_300.${imageSplit[1]} 300w
          `}
          sizes="
          (min-width: 992px) 25vw,
          (min-width: 768px) 33vw,
          50vw
          "
          role="presentation"
        />
        {image.caption && <span>{image.caption}</span>}
      </div>
    )
  })

  const makeFilePath = (filename) => {
    const imageSplit = filename.split(".")
    return `${props.path}/${imageSplit[0]}_2500.${imageSplit[1]}`
  }

  return (
    <>
      <h2 className="gallery-title" id="image-gallery">
        Image Gallery
      </h2>
      <span className="gallery-subtitle">Click an image to enlarge</span>
      <div className="row">
        {images}
        {isOpen && (
          <Lightbox
            mainSrc={makeFilePath(props.images[imageIndex].filename)}
            nextSrc={makeFilePath(
              props.images[(imageIndex + 1) % images.length].filename
            )}
            prevSrc={makeFilePath(
              props.images[(imageIndex + images.length - 1) % images.length]
                .filename
            )}
            onCloseRequest={() => setIsOpen(false)}
            onMovePrevRequest={() =>
              setImageIndex((imageIndex + images.length - 1) % images.length)
            }
            onMoveNextRequest={() =>
              setImageIndex((imageIndex + 1) % images.length)
            }
          />
        )}
      </div>
    </>
  )
}

export default ImageGallery
