import React, { useEffect, useState, createRef } from "react"
import Axios from "axios"
import Moment from "react-moment"
import "moment-timezone"
import { navigate } from "@reach/router"
import Layout from "../components/Layout"
import Message from "../components/Message"
import Loader from "../components/Loader"
import SEO from "../components/SEO"
import Heading from "../components/Layout/heading"
import Content from "../components/Layout/content"
import ImageGallery from "../components/ImageGallery"
import Button from "../components/Button"

import "./auction.scss"

const auctionsAPI = process.env.API_AUCTIONS
const imagesLocation = process.env.AUCTION_IMAGES
const cataloguesLocation = process.env.AUCTION_CATALOGUES

const Auction = (props) => {
  const [auction, setAuction] = useState({
    images: [],
  })
  const [isLoading, setIsLoading] = useState(true)
  const [message, setMessage] = useState()
  const imageGallery = createRef()

  useEffect(() => {
    Axios.get(`${auctionsAPI}/${props.id}`)
      .then((response) => {
        setAuction(response.data)
        setIsLoading(false)
      })
      .catch((error) => {
        if (error.response.status === 404) {
          navigate("/404")
        } else {
          setMessage({
            type: "danger",
            text: error.response.data.message,
          })
          setIsLoading(false)
        }
      })
    // eslint-disable-next-line
  }, [])

  return (
    <Layout location={props.location}>
      {isLoading ? (
        <Loader />
      ) : message ? (
        <Message type={message.type} message={message.text} />
      ) : (
        <>
          <SEO title={auction.title} description={auction.title} />
          <Heading title={auction.title} />
          {auction.endDate <= auction.startDate ? (
            <span className="auction-date">
              <Moment
                format="dddd Do MMMM @ hh:mma"
                date={auction.startDate}
                unix
                local
                tz="Australia/Sydney"
              />
            </span>
          ) : (
            <>
              <span className="auction-date">
                Starts:{" "}
                <Moment
                  format="dddd Do MMMM @ hh:mma"
                  date={auction.startDate}
                  unix
                  local
                  tz="Australia/Sydney"
                />
              </span>
              <br />
              <span className="auction-date">
                Ends:{" "}
                <Moment
                  format="dddd Do MMMM @ hh:mma"
                  date={auction.endDate}
                  unix
                  local
                  tz="Australia/Sydney"
                />
              </span>
            </>
          )}
          <div className="row">
            <div className="col">
              {auction.catalogue && (
                <a
                  href={`${cataloguesLocation}/${auction.catalogue}`}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <Button
                    btnStyle="sca-primary"
                    customClass="btn-icon-right btn-margin-right"
                    icon="book-open"
                  >
                    View Catalogue
                  </Button>
                </a>
              )}
              {auction.images.length > 0 && (
                <Button
                  btnStyle="sca-primary"
                  customClass="btn-icon-right"
                  icon="images"
                  callBack={() => {
                    window.scrollTo({
                      top: imageGallery.current.offsetTop,
                      left: 0,
                      behavior: "smooth",
                    })
                  }}
                >
                  Image Gallery
                </Button>
              )}
            </div>
          </div>
          <Content content={auction.content} />
          {auction.images.length > 0 && (
            <div className="row" ref={imageGallery}>
              <div className="col">
                <ImageGallery images={auction.images} path={imagesLocation} />
              </div>
            </div>
          )}
        </>
      )}
    </Layout>
  )
}

export default Auction
